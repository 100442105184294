import { filterConfig } from '@comp/page/common/filter/combine/filter-modal/meta'
import { isValid, clearHtmlTag } from '@lib/utils'
import { forEach, map, isArray, isFunction, isObject, keys, isString } from 'lodash-es'

export const modeType = {
  single: 'list1',
  listing: 'list',
  map: 'map',
}

function toSlug(str) {
  const res = clearHtmlTag(str)
  return res
}

const parseLib = {
  parseArray: ({ value, rule, itemSeprator }) => {
    if(!isValid(value)) {
      return []
    }

    return map(
      String(value).split(','),
      (v) => (rule.object 
        ? parseLib.parseObject({ value: v, rule, itemSeprator })
        : parseLib.parseItem({ value: v, rule }))
    )
  },
  parseObject: ({ value, rule, itemSeprator }) => {
    if(!isValid(value)) {
      return {}
    }

    const tmp = value.split(itemSeprator)
    const len = tmp.length

    if(len > 1) {
      const labelArr = tmp.slice(0, len - 1)
      return {
        label: labelArr.join(' '),
        value: parseLib.parseItem({ value: tmp[len - 1], rule }),
        slug: labelArr.join(itemSeprator)
      }
    }

    return { value }
  },
  parseItem: ({ value, rule }) => (
    rule.numberic
      ? parseFloat(value)
      : value
  ),
  parseApiArray: ({ value, rule }) => (
    isArray(value)
      ? map(value, (v) => (rule.object
        ? parseLib.parseApiObject({ value: v, rule })
        : parseLib.parseApiItem({ value: v, rule })
      )).join(',')
      : isObject(value)
        ? parseLib.parseApiObject({ value, rule })
        : parseLib.parseApiItem({ value, rule })
  ),
  parseApiObject: ({ value, rule }) => (
    isObject(value)
      ? value.value
      : parseLib.parseApiItem({ value, rule })
  ),
  parseApiItem: ({ value }) => String(value),
  stringifyArray: ({ value, rule, prop, itemSeprator }) => {
    const r = rule.array && isArray(value)
      ? map(value, (v) => {
        if(rule.object && isObject(v)) {
          const label = v.slug || v.label
          const tmp = []
          isValid(label) && tmp.push(toSlug(label))
          isValid(v.value) && tmp.push(v.value)

          return tmp.join(itemSeprator)
        }
        return v
      })
      : []

    return r.length > 0 
      ? `${prop}${itemSeprator}${r.join(',')}` 
      : rule.empty ? prop : ''
  },
  stringifyObject: ({ value, rule, prop, itemSeprator }) => {
    if(rule.object && isObject(value)) {
      const tmp = []
      const label = value.slug || value.label

      isValid(label) && tmp.push(toSlug(label))
      isValid(value.value) && tmp.push(value.value)

      return tmp.length > 0
        ? `${prop}${itemSeprator}${tmp.join(itemSeprator)}`
        : rule.empty ? prop : ''
    }

    return rule.empty ? prop : ''
  },
  stringifyItem: ({ value, rule, prop, itemSeprator }) => {
    if(!isValid(value)) {
      return rule.empty ? prop : ''
    } 

    return `${prop}${itemSeprator}${value}`
  },
}

function getValueV2({ prop, value, seprator, itemSeprator, rule }) {
  const val = value.join(itemSeprator)
  const params = { prop, value: val, seprator, itemSeprator, rule }

  if(!rule) {
    return val
  }
  
  if(isFunction(rule.getter)) {
    return rule.getter(params)
  }
  
  if(rule.array) {
    return parseLib.parseArray(params)
  }
  
  if(rule.object) {
    return parseLib.parseObject(params)
  }

  return parseLib.parseItem(params)
}

/**
 * seprator: 字段分割()
 * itemSeprator: 字段中的值分割
 * seprator 与 itemSeprator的值不能一样，否则会报错
 */
export function parseStrToObject(str, rules, seprator = '_', itemSeprator = '-') {
  const letters = decodeURIComponent(String(str)).split(seprator)
  const result = {}
  const keywords = ['with', 'in']

  letters.forEach((item) => {
    const [key, ...value] = item.split(itemSeprator)
    if (keywords.indexOf(key) > -1) return

    const rule = rules[key]

    if (rule) {
      // 如果是 Boolean 类型
      if (rule.bool) {
        result[key] = true
      // 有内容
      } else {
        result[key] = getValueV2({ prop: key, value, seprator, itemSeprator, rule })
      }
    }
  })

  return result
}

export function getApiSearchParam(target, rules, limit = 20) {
  const result = {}
  forEach(keys(target), (key) => {
    const rule = rules[key]
    const value = target[key]
    let tmp
    
    if (!rule || rule.apiDisabled) return

    if (rule.bool) {
      tmp = true
    } else if(isFunction(rule.apiGetter)) {
      tmp = rule.apiGetter({ value, rule })
    } else if(rule.array) {
      tmp = parseLib.parseApiArray({ value, rule })
    } else if(rule.object) {
      tmp = parseLib.parseApiObject({ value, rule })
    } else {
      tmp = parseLib.parseApiItem({ value, rule })
    }

    const apiProp = rule.prop || key
    result[apiProp] = tmp
  })

  return {
    ...result,
    offset: ((target?.page || 1) - 1) * limit,
    limit
  }
}

export function stringifyToUrl(target, rules, seprator = '_', itemSeprator = '-') {
  const result = []
  const tmp = {} // 保存已处理的字段名称
  
  const stringifySnippet = (prop) => {
    const val = target[prop]
    const rule = rules[prop]

    if(val === undefined) return ''
    
    tmp[prop] = true
    
    if(isFunction(rule.setter)) {
      return rule.setter({ value: val, rule, prop, seprator, itemSeprator })
    }

    if(rule.bool) {
      return prop
    }

    if(rule.array) {
      return parseLib.stringifyArray({ value: val, rule, prop, seprator, itemSeprator })
    }

    if(rule.object) {
      return parseLib.stringifyObject({ value: val, rule, prop, seprator, itemSeprator })
    }

    return parseLib.stringifyItem({ value: val, rule, prop, seprator, itemSeprator })
  }

  // 先处理自定义sorter
  if(isArray(rules.$sorter)) {
    forEach(rules.$sorter, (item) => {
      if(isString(item) && !tmp[item]) {
        const snippet = stringifySnippet(item)
        snippet && result.push(snippet)
      } else if(isObject(item)) {
        const groupTmp = []

        if(isArray(item.items)) {
          forEach(item.items, (key) => {
            const snippet = stringifySnippet(key)
            snippet && groupTmp.push(snippet)
          })
        }

        if(groupTmp.length > 0) {
          item.groupName && groupTmp.unshift(item.groupName)
          result.push(...groupTmp)
        }
      }
    })
  }

  // 处理不在 $sorter 中的字段
  forEach(keys(rules), (key) => {
    if(tmp[key] || key.indexOf('$') > -1) {
      return
    }

    const snippet = stringifySnippet(key)
    snippet && result.push(snippet)
  })

  return result.join(seprator)
}

/**
 * 解析字符串，并返回解析是否通过的字段
 */
export function parseAndCheckV2(str, rules) {
  let param = null
  let isPass = true
  
  try{
    param = parseStrToObject(str, rules)
  } catch(err) { isPass = false }
  
  return { param, isPass }
}

/**
 * 将 filter 字符串转换成 数组
 */
export function filtersToArray(type, filters) {
  const tmp = []
  const { fullname } = filterConfig[type] || {}

  if(isString(filters) && fullname) {
    const target = filters.split('_')
    
    for(let i = 0, len = target.length; i < len; i += 2) {
      const label = fullname[target[i]] || target[i]
      tmp.push({ label, value: target[i + 1] })
    }
  }

  return tmp
}
