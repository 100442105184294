import { getRouteUrl } from './lib'

/**
 * 搜索中介
 */
export function findAnAgent(locale) {
  return getRouteUrl('/find-an-agent', null, locale)
}

/**
 * 中介列表
 */
export function agents(slug, locale) {
  const pathname = slug || 'page-1'
  return getRouteUrl(`/agents/${pathname}`, null, locale)
}

/**
 * 中介详情
 */
export function detail(slug, locale) {
  return getRouteUrl('/agent/{slug}', { slug }, locale)
}

/**
 * office详情
 */
export function officeDetail(slug, locale) {
  return getRouteUrl('/office/{slug}', { slug }, locale)
}
